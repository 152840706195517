var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-sheet",
        {
          staticClass:
            "addressBlock d-flex justify-space-between align-center mb-7",
          attrs: { color: "transparent" },
        },
        [
          _c("mew-blockie", { attrs: { address: _vm.address } }),
          _c("span", { staticClass: "font-weight-heavy pr-15" }, [
            _vm._v(_vm._s(_vm.address)),
          ]),
        ],
        1
      ),
      !_vm.hasDomains
        ? _c(
            "div",
            [
              _c(
                "mew-alert",
                {
                  staticClass:
                    "font-weight-light d-flex justify-space-between align-center mb-7 mt-7",
                  attrs: { "hide-close-icon": "" },
                },
                [
                  _vm._v(
                    " No ENS names have their ETH Address records set to this address. Only ENS names that point to your Ethereum account can be set as your Primary ENS Name. "
                  ),
                ]
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "d-flex flex-column justify-space-between" },
            [
              _c("div", { staticClass: "mew-heading-2 mb-2" }, [
                _vm._v("Your Domains:"),
              ]),
              _c(
                "div",
                { staticClass: "d-flex justify-space-between" },
                [
                  _c("mew-select", {
                    staticClass: "domain-dropdown",
                    attrs: {
                      "normal-dropdown": "",
                      value: _vm.selectedDomain,
                      "filter-placeholder": "Search for Domain",
                      items: _vm.domainListItems,
                      "error-messages": _vm.selectedDomain.error,
                    },
                    on: { input: _vm.setDomain },
                  }),
                  _c("mew-button", {
                    staticClass: "set-button",
                    attrs: {
                      title: "Register",
                      "btn-size": "xlarge",
                      loading: _vm.selectedDomain.loading,
                      disabled: _vm.disableRegister,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.setReverseRecord(_vm.selectedDomain)
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
      _vm.hasReverseRecordNames
        ? _c("div", [
            _c("div", { staticClass: "mew-heading-2 mb-2" }, [
              _vm._v("Reverse Names:"),
            ]),
            _c("div", { staticClass: "d-flex justify-space-between" }, [
              _vm._v(" " + _vm._s(_vm.reverseRecordNames) + " "),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }